import { FC, PropsWithChildren, useEffect, useState } from 'react';
import clsx from 'clsx';

export const ShowOnHydrate: FC<PropsWithChildren> = props => {
  const {
    children,
  } = props;

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <div
      className={clsx(
        {
          '[ invisible ]': !visible
        },
      )}
    >
      {children}
    </div>
  );
};
